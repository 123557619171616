import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/picture2.png","alt":null,"title":"Aerial view of the farmland that was once the Lago di Castiglione "}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/aerial-photos_2010_-17-.jpg","alt":null,"title":"Aerial view of the Gabii excavation on the edge of the Lago di Castiglione "}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Lago di Castiglione","key":"lago-di-castiglione","parentKey":"the-environmant-of-gabii"};

  